import "../styles/index.scss"
import React from "react"
// import Header from '../components/header';
import Header from "../components/HeaderConfirm"
// import TweenMax from 'gsap';
import SEO from "../components/seo"
// import ReactPixel from 'react-facebook-pixel';
// import fbTrack from '../lib/fbq';

class Contact extends React.Component {
  // handleSubmit = (e) => {
  // 	e.preventDefault();

  // 	ReactPixel.track('track', 'Contact');
  // };
  componentDidMount() {
    // ensure gatsby builds on server
    // if (typeof window !== 'undefined') { // gatsby
    //   // let ff = !(window.mozInnerScreenX == null);
    //   let isFirefox = typeof InstallTrigger !== 'undefined';
    //   let isMobile = (window.innerWidth <= 800 && window.innerHeight <= 930);
    //     // do not init for firefox && mobile
    //     // if (isMobile || isFirefox) {
    //     if(isFirefox) {
    //       TweenMax.set(this.container, { force3D: true, rotation: 0.01 });
    //     }
    //     else if (isMobile) {
    //       TweenMax.set(this.container, { force3D: true, rotation: 0.01 });
    //         let luxy = useLuxy('#luxy');
    //         luxy.init({
    //             wrapperSpeed: 1
    //         });
    //     } else {
    //       // enforce transforms to GPU
    //       TweenMax.set(this.container, { force3D: true, rotation: 0.01 });
    //         let luxy = useLuxy('#luxy');
    //         luxy.init({
    //             wrapperSpeed: 0.09
    //         });
    //     }
    //   };
  }
  render() {
    return (
      <React.Fragment>
        <SEO
          title="Contact Us"
          description="Let’s not make this complicated. Simply let us know what you need help with."
        />
        <Header path={this.props.location.pathname} />
        <div className="contact-container">
          <div className="contact-subcontainer">
            {/* TITLE */}
            <div className="section-header">
              Let’s not make this complicated.
            </div>
            <div className="section-header">
              Simply let us know what you need help with.
            </div>
            {/* CONTACT FORM */}
            <form
              // onSubmit={() => this.handleSubmit()}
              className="contact-form-wrapper"
              netlify-honeypot="bot-field"
              action="/received"
              name="contact"
              method="POST"
              data-netlify="true"
            >
              <input type="hidden" name="form-name" value="contact" />

              {/* <div className="contact-form-wrapper"> */}
              <div className="contact-form-container">
                {/* FORM */}
                <div className="contact-form-name">
                  <label>
                    My name is{" "}
                    <input
                      style={{ textAlign: "center" }}
                      type="text"
                      name="name"
                      // placeholder="First & Last Name"
                    />
                  </label>
                  <br />
                  <br />
                </div>
                <div className="contact-form-companyname">
                  <label>
                    My order number is{" "}
                    <input
                      className="contact-input"
                      // placeholder="(If applicable)"
                      style={{ textAlign: "center" }}
                      type="text"
                      name="ordernumber"
                    />
                  </label>
                  <br />
                  <br />
                </div>
                <div className="contact-form-request">
                  <label>
                    I need help with
                    <input
                      className="contact-input"
                      type="text"
                      name="description"
                      // placeholder="Your Request"
                    />
                  </label>
                  <br />
                  <br />
                </div>
                <div className="contact-form-email">
                  <label>
                    My email is{" "}
                    <input
                      className="contact-input"
                      type="email"
                      name="email"
                      // placeholder="Email Address"
                    />
                  </label>
                  <br />
                  <br />
                </div>

                <div className="contact-form-button-wrapper">
                  <button className="contact-form-button" type="submit">
                    SEND
                  </button>
                </div>
              </div>

              {/* </div> */}
            </form>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default Contact
